import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/bg.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/cz.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/de.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/dk.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/ee.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/es.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/fi.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/fr.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/hu.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/lt.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/lv.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/no.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/pl.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/ro.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/ua.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/uk.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/public/flags/us.svg");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/components/cms/config/footer.tsx");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/components/cms/config/header.tsx");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/utils/device.ts");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/utils/screen.ts");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/utils/scroll.ts");
import(/* webpackMode: "eager" */ "/app/apps/dotcom/src/utils/tracking.ts");
import(/* webpackMode: "eager" */ "/app/node_modules/@storyblok/react/dist/story.mjs")