"use client";

import type { PageContext } from "~lib/storyblok";
import { useScreenSize } from "~utils/screen";

import { DesktopNav } from "./desktop-nav";
import { MobileNav } from "./mobile-nav";
import type { HeaderBlok, HeaderNavValues } from "./types";

type HeaderProps = {
  blok: HeaderBlok | undefined;
  initialValue?: HeaderNavValues; // Demo prop for storybook demo purposes
} & PageContext;

export function Header({ blok, initialValue, ...context }: HeaderProps) {
  if (!blok) return null;

  const { isMobile } = useScreenSize();

  if (isMobile)
    return <MobileNav initialValue={initialValue} blok={blok} {...context} />;

  return <DesktopNav initialValue={initialValue} blok={blok} {...context} />;
}
