"use client";

import { renderRichText, storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import { ampli } from "~lib/ampli";
import { type PageContext, getLinkProps } from "~lib/storyblok";
import { cn } from "~utils";
import agreenaLogo from "../../../../public/agreena-logo.svg";
import type { FooterBlok, FooterLinkBlok } from "./types";

type FooterProps = {
  blok: FooterBlok | undefined;
} & PageContext;

export function Footer({ blok, ...context }: FooterProps) {
  if (!blok) return null;

  return (
    <footer
      {...storyblokEditable(blok)}
      className={cn(
        "section-sky bg-others-white",
        "relative flex px-sm py-3xl-4xl sm:px-3xl-4xl",
      )}
    >
      <div
        className={cn(
          "section-sky text-content-text",
          "z-0 m-auto grid w-full max-w-[58em] grid-cols-1 place-items-start gap-xl",
        )}
      >
        <Image src={agreenaLogo} width="131" height="32" alt="Agreena" />

        <section className="grid place-items-start gap-xl gap-y-md lg:col-start-2 lg:row-span-2 sm:grid-cols-2 md:gap-y-xl">
          <FooterLinkGroup
            text={blok.footerForFarmersText}
            items={blok.footerForFarmersItems}
            {...context}
          />

          <FooterLinkGroup
            text={blok.footerForCompaniesText}
            items={blok.footerForCompaniesItems}
            {...context}
          />

          <FooterLinkGroup
            text={blok.footerCompanyText}
            items={blok.footerCompanyItems}
            {...context}
          />

          <FooterLinkGroup
            text={blok.footerResourcesText}
            items={blok.footerResourcesItems}
            {...context}
          />
        </section>

        <hr className="w-full border-border/300 md:hidden" />

        <section className="t-default-sm grid place-content-start gap-lg lg:col-start-1 lg:row-start-2 lg:grid-cols-1 sm:grid-cols-2">
          <div
            className="[&_b]:t-strong-sm [&_b]:mb-2xs [&_b]:block [&_a]:text-satellite-accent"
            // biome-ignore lint/security/noDangerouslySetInnerHtml: CMS is safe
            dangerouslySetInnerHTML={{
              __html: renderRichText(blok.footerAddressPrimary),
            }}
          />

          <div
            className="[&_b]:t-strong-sm [&_b]:mb-2xs [&_b]:block [&_a]:text-satellite-accent"
            // biome-ignore lint/security/noDangerouslySetInnerHtml: CMS is safe
            dangerouslySetInnerHTML={{
              __html: renderRichText(blok.footerAddressSecondary),
            }}
          />
        </section>

        <section className="-mt-xs t-strong-sm grid gap-2xs md:mt-[0px]">
          <h3>{blok.footerSoMeText}</h3>

          <div className="flex flex-wrap gap-sm">
            {blok.footerSoMeItems?.map(item => (
              <a key={item._uid} {...getLinkProps(item.link, context)}>
                <Image
                  width="24"
                  height="24"
                  src={item.icon?.filename ?? ""}
                  alt={item.text}
                />
              </a>
            ))}
          </div>
        </section>

        <section className="-mt-xs t-default-xs grid gap-3xs md:mt-[0px]">
          <p>{blok.footerTermsText}</p>

          <div className="flex flex-wrap gap-xs text-satellite-accent">
            {blok.footerTermsItems?.map(item => (
              <a key={item._uid} {...getLinkProps(item.link, context)}>
                {item.text}
              </a>
            ))}
          </div>
        </section>
      </div>
    </footer>
  );
}

type FooterLinkGroupProps = {
  text: string;
  items: FooterLinkBlok[];
} & PageContext;

function FooterLinkGroup({ text, items, ...context }: FooterLinkGroupProps) {
  return (
    <div className="t-strong-md grid gap-sm">
      <h3>{text}</h3>

      <div className="t-default-md grid gap-3xs text-satellite-accent">
        {items?.map(item => {
          const linkProps = getLinkProps(item.link, context);

          return (
            <a
              key={item._uid}
              {...linkProps}
              // biome-ignore lint/a11y/useValidAnchor: we just want some extra tracking on this bad boi.
              onClick={() => {
                if (!linkProps.href) return;

                ampli.navigationLinkClicked({
                  navigationArea: "footer",
                  navigationLinkHref: linkProps.href,
                });
                ampli.flush();
              }}
            >
              {item.text}
            </a>
          );
        })}
      </div>
    </div>
  );
}
