"use client";

import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import { useMemo, useState } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { markets } from "~app/config";
import { Button, Icon } from "~components/ui";
import { ampli } from "~lib/ampli";
import { type PageContext, getLinkProps } from "~lib/storyblok";
import { cn } from "~utils";
import { useWindowScroll } from "~utils/scroll";
import { getSurfaceVariant } from "~utils/style";
import agreenaLogo from "../../../../public/agreena-logo.svg";
import { RichText, isTextEmpty } from "../rich-text";
import styles from "./mobile-nav.module.css";
import type {
  BannerBlok,
  HeaderBlok,
  HeaderNavValues,
  NavItemBlok,
} from "./types";

type MobileNavProps = {
  blok: (HeaderBlok & BannerBlok) | undefined;
  initialValue?: HeaderNavValues;
} & PageContext;

type Value = "markets" | "links" | undefined;
type SubValue = "farmers" | "companies" | "resources" | "more" | undefined;

export function MobileNav({ blok, initialValue, ...context }: MobileNavProps) {
  const [value, setValue] = useState<Value>(
    initialValue && initialValue !== "markets" ? "links" : initialValue,
  );
  const [subValue, setSubValue] = useState<SubValue>(
    initialValue && initialValue !== "markets" ? initialValue : undefined,
  );

  const {
    isAtStart: isScrollAtStart,
    direction: scrollDirection,
    lastDirection: lastScrollDirection,
    scrollPosition,
  } = useWindowScroll({ startThreshold: 100, minSpeed: 100 });

  /**
   * Hides the nav when we scroll down past the first 100px of the window,
   * and shows it again when scrolling up.
   * Any scroll slower than 100px per second is ignored, to prevent "accidental"
   * scrolls.
   */
  const isHidden = useMemo(
    () =>
      !value &&
      !isScrollAtStart &&
      (scrollDirection === "down" || lastScrollDirection === "down"),
    [value, isScrollAtStart, scrollDirection, lastScrollDirection],
  );

  const currentMarket = useMemo(
    () => markets.find(item => item.key === context.marketId),
    [context],
  );

  const homeLinkProps = useMemo(
    () => blok && getLinkProps(blok.homeLink, context),
    [blok, context],
  );
  const loginLinkProps = useMemo(
    () => blok && getLinkProps(blok.headerLoginLink, context),
    [blok, context],
  );
  if (!blok) return null;

  const hasBanner = useMemo(
    () =>
      blok?.bannerText &&
      !isTextEmpty(blok?.bannerText) &&
      // when we are on the page the banner is linking to, don't show it.
      (!blok.bannerCTALink || blok.bannerCTALink?.id !== context.uuid),
    [blok, context],
  );

  return (
    <>
      {hasBanner && (
        <div
          className={cn(
            "flex flex-wrap items-center border-content-text border-b p-xs text-content-text",
            blok.bannerTheme,
            getSurfaceVariant(blok.bannerBackgroundVariant),
          )}
          {...storyblokEditable(blok)}
        >
          <div className="flex gap-xs">
            {blok.bannerIcon && (
              <Icon
                name={blok.bannerIcon}
                className="t-strong-lg mt-[2px] scale-x-[-1]"
                weight="duotone"
              />
            )}
            {blok.bannerText && (
              <RichText data={blok.bannerText} {...context} />
            )}
          </div>

          {blok.bannerCTAText && blok.bannerCTALink && (
            <Button
              variant="ternary"
              {...getLinkProps(blok.bannerCTALink, context)}
              className="ml-auto"
              size="small"
            >
              {blok.bannerCTAText}
            </Button>
          )}
        </div>
      )}
      <div className={styles["header-wrapper"]}>
        <header
          {...storyblokEditable(blok)}
          className={styles.header}
          data-state={value ? "open" : "closed"}
          data-value={value}
          data-hidden={isHidden}
          data-has-banner={hasBanner}
        >
          <div className={styles.backdrop} />

          <RemoveScroll
            enabled={Boolean(!isHidden && value)}
            className={styles.wrapper}
          >
            <NavigationMenu.Root
              className={styles.nav}
              value={value}
              onValueChange={newValue => setValue(newValue as typeof value)}
              orientation="vertical"
            >
              <NavigationMenu.List className={styles.list}>
                <NavigationMenu.Item
                  className={styles["logo-item"]}
                  value="logo"
                >
                  <NavigationMenu.Link
                    {...homeLinkProps}
                    onClick={() => {
                      if (!homeLinkProps?.href) return;

                      ampli.navigationLinkClicked({
                        navigationArea: "header",
                        navigationLinkHref: homeLinkProps.href,
                      });
                      ampli.flush();
                    }}
                  >
                    <Image src={agreenaLogo} height={32} alt="Agreena logo" />
                  </NavigationMenu.Link>
                </NavigationMenu.Item>

                <NavigationMenu.Item
                  className={styles["markets-item"]}
                  value="markets"
                >
                  <NavigationMenu.Trigger className={styles["markets-trigger"]}>
                    <Image
                      src={currentMarket?.icon}
                      width="24"
                      height="24"
                      alt={currentMarket?.name ?? ""}
                    />

                    <Icon name="CaretDown" weight="bold" />
                  </NavigationMenu.Trigger>

                  <NavigationMenu.Content className={styles["markets-content"]}>
                    <NavigationMenu.Sub>
                      <NavigationMenu.List className={styles["markets-list"]}>
                        <NavigationMenu.Item>
                          {blok.headerMarketsText && (
                            <p className={styles["markets-text"]}>
                              {blok.headerMarketsText}
                            </p>
                          )}

                          <div className={styles["markets-grid"]}>
                            {markets.map(market => (
                              <a
                                key={market.key}
                                className={styles["market-link"]}
                                href={market.url}
                                data-state={
                                  market === currentMarket
                                    ? "active"
                                    : undefined
                                }
                              >
                                <Image src={market.icon} alt={market.name} />
                                <span>{market.name}</span>
                              </a>
                            ))}
                          </div>
                        </NavigationMenu.Item>

                        <NavigationMenu.Item className={styles["footer-item"]}>
                          <NavigationMenu.Link
                            asChild
                            {...loginLinkProps}
                            onClick={() => {
                              if (!loginLinkProps?.href) return;

                              ampli.navigationLinkClicked({
                                navigationArea: "header",
                                navigationLinkHref: loginLinkProps.href,
                              });
                              ampli.flush();
                            }}
                          >
                            <Button variant="secondary">
                              {blok.headerLoginText}
                            </Button>
                          </NavigationMenu.Link>
                        </NavigationMenu.Item>
                      </NavigationMenu.List>
                    </NavigationMenu.Sub>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Item
                  className={styles["links-item"]}
                  value="links"
                >
                  <NavigationMenu.Trigger className={styles["links-trigger"]}>
                    {value === "links" ? (
                      <Icon name="X" />
                    ) : (
                      <svg
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>three lines</title>
                        <path
                          d="M18 7C18 7.19891 17.921 7.38968 17.7803 7.53033C17.6397 7.67098 17.4489 7.75 17.25 7.75H0.75C0.551088 7.75 0.360322 7.67098 0.21967 7.53033C0.0790178 7.38968 0 7.19891 0 7C0 6.80109 0.0790178 6.61032 0.21967 6.46967C0.360322 6.32902 0.551088 6.25 0.75 6.25H17.25C17.4489 6.25 17.6397 6.32902 17.7803 6.46967C17.921 6.61032 18 6.80109 18 7ZM0.75 1.75H17.25C17.4489 1.75 17.6397 1.67098 17.7803 1.53033C17.921 1.38968 18 1.19891 18 1C18 0.801088 17.921 0.610322 17.7803 0.46967C17.6397 0.329018 17.4489 0.25 17.25 0.25H0.75C0.551088 0.25 0.360322 0.329018 0.21967 0.46967C0.0790178 0.610322 0 0.801088 0 1C0 1.19891 0.0790178 1.38968 0.21967 1.53033C0.360322 1.67098 0.551088 1.75 0.75 1.75ZM17.25 12.25H0.75C0.551088 12.25 0.360322 12.329 0.21967 12.4697C0.0790178 12.6103 0 12.8011 0 13C0 13.1989 0.0790178 13.3897 0.21967 13.5303C0.360322 13.671 0.551088 13.75 0.75 13.75H17.25C17.4489 13.75 17.6397 13.671 17.7803 13.5303C17.921 13.3897 18 13.1989 18 13C18 12.8011 17.921 12.6103 17.7803 12.4697C17.6397 12.329 17.4489 12.25 17.25 12.25Z"
                          fill="#0E0341"
                        />
                      </svg>
                    )}
                  </NavigationMenu.Trigger>

                  <NavigationMenu.Content className={styles["links-content"]}>
                    <NavigationMenu.Sub
                      // Need to set the default dynamically like this
                      value={subValue}
                      onValueChange={newValue =>
                        setSubValue(newValue as typeof subValue)
                      }
                      orientation="vertical"
                      defaultValue="farmers"
                      className={styles["links-sub-nav"]}
                      data-state={
                        value === "links" && subValue ? "open" : "closed"
                      }
                      data-value={subValue}
                    >
                      <NavigationMenu.List className={styles["links-sub-list"]}>
                        <HeaderNavGroup
                          text={blok.headerFarmersText}
                          items={blok.headerFarmersItems}
                          {...context}
                          value="farmers"
                          isOpen={value === "links" && subValue === "farmers"}
                        />

                        <HeaderNavGroup
                          text={blok.headerCompaniesText}
                          items={blok.headerCompaniesItems}
                          {...context}
                          value="companies"
                          isOpen={value === "links" && subValue === "companies"}
                        />

                        <HeaderNavGroup
                          text={blok.headerResourcesText}
                          items={blok.headerResourcesItems}
                          {...context}
                          value="resources"
                          isOpen={value === "links" && subValue === "resources"}
                        />

                        <HeaderNavGroup
                          text={blok.headerMoreText}
                          items={blok.headerMoreItems}
                          {...context}
                          value="more"
                          isOpen={value === "links" && subValue === "more"}
                        />

                        <NavigationMenu.Item className={styles["footer-item"]}>
                          <NavigationMenu.Link asChild {...loginLinkProps}>
                            <Button variant="secondary">
                              {blok.headerLoginText}
                            </Button>
                          </NavigationMenu.Link>
                        </NavigationMenu.Item>
                      </NavigationMenu.List>
                    </NavigationMenu.Sub>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>
              </NavigationMenu.List>

              <NavigationMenu.Viewport className={styles.viewport} />
            </NavigationMenu.Root>
          </RemoveScroll>
        </header>
      </div>
    </>
  );
}

type NavGroupProps = {
  text?: string;
  items: NavItemBlok[] | null;
  isOpen?: boolean;
  value: string;
} & PageContext;

function HeaderNavGroup({
  items,
  text,
  value,
  isOpen,
  ...context
}: NavGroupProps) {
  if (!items?.length) return null;

  return (
    <NavigationMenu.Item
      className={styles["links-sub-item"]}
      value={value}
      data-value={value}
      data-state={isOpen ? "open" : "closed"}
    >
      <NavigationMenu.Trigger className={styles["links-sub-trigger"]}>
        <div>
          <span>{text}</span>
          <Icon name="CaretDown" weight="bold" />
        </div>
      </NavigationMenu.Trigger>

      <NavigationMenu.Content className={styles["links-sub-content"]}>
        {items.map(item => {
          const linkProps = getLinkProps(item.link, context);

          return (
            <a
              key={item._uid}
              className={styles["nav-link"]}
              {...linkProps}
              // biome-ignore lint/a11y/useValidAnchor: we just need an extra click handler, href is there.
              onClick={() => {
                if (!linkProps.href) return;

                ampli.navigationLinkClicked({
                  navigationArea: "header",
                  navigationLinkHref: linkProps.href,
                });
                ampli.flush();
              }}
            >
              <Icon name={item.icon} weight="duotone" />

              <div>
                <div
                  className={cn(
                    !item.text && "!t-default-sm mt-3xs",
                    styles["nav-link-title"],
                  )}
                >
                  {item.title}
                </div>
                {item.text && (
                  <p className={styles["nav-link-text"]}>{item.text}</p>
                )}
              </div>
            </a>
          );
        })}
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
}
